import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supportFetch } from '../../middleware/supportFetch';
import Handle from '../errors/ErrorHandler';

const initialState = {
  case: null as any,
  files: [],
  status: 'idle',
  error: null,
};

export const fetchCaseByQuery = createAsyncThunk<
  any, {
    caseNumber: string
  }
>('case/fetchCase', async (param: any) => {
  return new Promise((resolve, reject) => {
    supportFetch.get(`/public/v1.0/case/${param.caseNumber}`)
      .then((res: any) => {
        console.debug({ data: res });

        if (Handle.WasThereAnError(res)) {
          reject(res);
        } else {
          resolve(res);
        }
      }).catch(err => {
        console.error({ err });
        reject(err);
      });
  });
});

export const fetchCaseAttachments = createAsyncThunk<
  any,
  {
    caseNumber: string;
    paging: any;
    filter: any;
  }
>('case/fetchCaseAttachments', async (query: any) => {
  if (!query.paging.rows) {
    query.paging.rows = 10;
  }

  if (!query.paging.page) {
    query.paging.page = 0;
  }

  return new Promise((resolve, reject) => {
    supportFetch.post(`/public/v1.0/case/attachments`, {
      ...query,
    }).then((res: any) => {
      if (Handle.WasThereAnError(res)) {
        reject(res);
      } else {
        // retain/pass values back
        if (query.filter) {
          res.predicate = query.filter;
        }

        if (query.paging.sortBy) {
          res.paginate.sortBy = query.paging.sortBy;
        }

        resolve(res);
      }
    }).catch(err => {
      reject(err);
    });
  });
});

export const fetchCaseComments = createAsyncThunk<
  any,
  {
    caseNumber: string;
    paging: any;
  }
>('case/fetchCaseComments', async (query: any) => {
  return new Promise((resolve, reject) => {
    supportFetch.post(`/public/v1.0/case/comments`, {
      ...query,
    }).then((res: any) => {
      if (Handle.WasThereAnError(res)) {
        reject(res);
      } else {
        if (query.saved_paginate) {
          res.saved_paginate = query.saved_paginate;
          resolve(res);
        } else {
          resolve(res);
        }
      }
    }).catch(err => {
      reject(err);
    });
  });
});

export const addCaseComment = createAsyncThunk<
  any,
  {
    caseNumber: string;
    mark_down_text: string;
  }
>('case/addCaseComment', async (query: any) => {
  return new Promise((resolve, reject) => {
    supportFetch.post(`/public/v1.0/case/${query.case_number}/comment`, {
      ...query,
      ...{ userLocale: Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Etc/UTC" },
    })
      .then((res: any) => {
        if (Handle.WasThereAnError(res)) {
          reject(res);
        } else {
          if (query.saved_paginate) {
            res.saved_paginate = query.saved_paginate;
          }

          resolve(res);
        }
      }).catch(err => {
        reject(err);
      });
  });
});

export const fetchFilesByQuery = createAsyncThunk<
  any,
  {
    cases?: string[];
    accounts?: string[];
    projects?: string[];
    page?: number;
    limit?: number;
  }
>('files/fetchFiles', async (query: any) => {
  if (!query.limit) {
    query.limit = 10;
  }

  if (!query.page) {
    query.page = 1;
  }

  return new Promise((resolve, reject) => {
    supportFetch.post(`/public/v1.0/files/byCases`, {
      ...query,
    }).then((res: any) => {
      if (Handle.WasThereAnError(res)) {
        reject(res);
      } else {
        resolve(res);
      }
    }).catch(err => {
      reject(err);
    });
  });
});

export const addCaseAttachments = createAsyncThunk<
  any,
  {
    files: [];
  }
>('case/addCaseAttachments', async (query: any) => {
  return new Promise((resolve, reject) => {
    supportFetch.post(`/public/v1.0/case/add/attachments`, {
      ...query,
    }).then((res: any) => {
      if (Handle.WasThereAnError(res)) {
        reject(res);
      } else {
        resolve(res);
      }
    }).catch(err => {
      reject(err);
    });
  });
});


export const deleteCaseAttachments = createAsyncThunk<
  any,
  {
    files: [];
  }
>('case/deleteCaseAttachments', async (query: any) => {
  return new Promise((resolve, reject) => {
    supportFetch.post(`/public/v1.0/case/delete/attachments`, {
      ...query,
    }).then((res: any) => {
      if (Handle.WasThereAnError(res)) {
        reject(res);
      } else {
        resolve(res);
      }
    }).catch(err => {
      reject(err);
    });
  });
});

export const updateCaseDetailsByQuery = createAsyncThunk<
  any, {
    number: string,
    severity: string,
    groupname: string,
    product: string,
    options: string,
  }
>('case/updateCaseDetails', async (query: any) => {
  return new Promise((resolve, reject) => {
    supportFetch.post(`/public/v1.0/case/${query.number}`, {
      ...query,
    }).then((res: any) => {
      if (Handle.WasThereAnError(res)) {
        reject(res);
      } else {
        resolve(res);
      }
    }).catch(err => {
      reject(err);
    });
  });

});

const caseSlice = createSlice({
  name: 'case',
  initialState,
  reducers: {
    pushStatus: (state, action) => {
      state.case.case.Status = action.payload;
    }
  },
  extraReducers: {
    [fetchCaseByQuery.pending.toString()]: (state, action) => {
      state.status = 'loading';
    },
    [fetchCaseByQuery.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded';
      state.case = action.payload;
    },
    [fetchCaseByQuery.rejected.toString()]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [fetchCaseAttachments.pending.toString()]: (state) => {
      state.status = 'loading';
    },
    [fetchCaseAttachments.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded';
      state.case.attachments = action.payload;
    },
    [fetchCaseAttachments.rejected.toString()]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [fetchCaseComments.pending.toString()]: (state) => {
      state.status = 'loading';
    },
    [fetchCaseComments.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded';
      state.case.comments = action.payload;
    },
    [fetchCaseComments.rejected.toString()]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addCaseComment.pending.toString()]: (state) => {
      state.status = 'loading';
    },
    [addCaseComment.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded';
      state.case.comments = action.payload;
    },
    [addCaseComment.rejected.toString()]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [fetchFilesByQuery.pending.toString()]: (state) => {
      state.status = 'loading';
    },
    [fetchFilesByQuery.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded';
      state.files = action.payload;
    },
    [fetchFilesByQuery.rejected.toString()]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addCaseAttachments.pending.toString()]: (state) => {
      state.status = 'loading';
    },
    [addCaseAttachments.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded';

      // When creating a new case, case is null so don't add attachments at,
      // this point, the attachments will show when the case is fullfilled.
      // TODO: probably need to visit this because I would have thought that
      // the case would have already been fullfilled
      if (state.case) {
        state.case.attachments = action.payload;
      }
    },
    [addCaseAttachments.rejected.toString()]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [deleteCaseAttachments.pending.toString()]: (state) => {
      state.status = 'loading';
    },
    [deleteCaseAttachments.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded';
      state.case.attachments = action.payload;
    },
    [deleteCaseAttachments.rejected.toString()]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [updateCaseDetailsByQuery.pending.toString()]: (state) => {
      state.status = 'loading';
    },
    [updateCaseDetailsByQuery.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded';
      action.payload.groupname && (state.case.case.Group__c = action.payload.groupname);
      action.payload.product && (state.case.case.Product__c = action.payload.product);
      action.payload.severity && (state.case.case.Severity__c = action.payload.severity);
      action.payload.storage && (state.case.case.Storage_Engine__c = action.payload.storage);
      action.payload.version && (state.case.case.MongoDB_Version__c = action.payload.version);
      action.payload.subject && (state.case.case.Subject = action.payload.subject);
    },
    [updateCaseDetailsByQuery.rejected.toString()]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const selectCase = (state: any) => {
  return state.case.case;
};

export const selectCaseEx = (state: any) => {
  return {
    data: state.case.case,
    dataFetchStatus: state.case.status,
  };
};

export const { pushStatus } = caseSlice.actions;

export const selectCaseIdentification = (state: any) => {
  return {
    Id: state.case.case.case.Id,
    CaseNumber: state.case.case.case.CaseNumber,
  }
};

export const selectAttachments = (state: any) => {
  return state.case.case.attachments;
};

export const selectComments = (state: any) => {
  return state.case.case.comments;
};

export const selectFiles = (state: any) => state.case.files;

export default caseSlice.reducer;
