import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getToken,
  getCoveoVisitorId,
  dispatchContextValues,
  buildCoveoSearchEngine,
} from '../ts-tools/Engine';
import { SearchEngine } from '@coveo/headless';

type TCoveoState = {
  coveo: CoveoState;
};

interface CoveoState {
  token: string;
  visitorId: string;
  engine?: SearchEngine;
  status?: string;
  error?: string;
}

const initialState: CoveoState = {
  token: '',
  visitorId: '',
  engine: undefined,
  status: 'idle',
  error: undefined,
};

export const setCoveoEngine = createAsyncThunk<CoveoState, any>(
  'coveo/setCoveoEngine',
  async (session: any, { rejectWithValue }) => {
    // Get the Visitor ID to use for Coveo Calls
    const visitorId: string = getCoveoVisitorId(session.visitorId);
    // Get the Token to use for Coveo Calls
    const token: string = await getToken();
    if (token) {
      // Build the Coveo Engine
      const engine: SearchEngine = buildCoveoSearchEngine(token, visitorId);
      // Dispatch Contexts
      dispatchContextValues(session, engine);
      // Return the Coveo State Obj
      const resp: CoveoState = {
        token: token,
        visitorId: visitorId,
        engine: engine,
      };

      return resp;
    } else {
      return rejectWithValue('No Token Found');
    }
  }
);

const coveoSlice = createSlice({
  name: 'coveo',
  initialState,
  reducers: {
    pushValue: (state, action) => {
      state.visitorId = action.payload;
    },
  },
  extraReducers: {
    [setCoveoEngine.pending.toString()]: state => {
      state.status = 'loading';
    },
    [setCoveoEngine.fulfilled.toString()]: (state, action) => {
      // Add any fetched Coveo token and engine to the state
      state.status = 'succeeded';
      state.token = action.payload.token;
      state.visitorId = action.payload.visitorId;
      state.engine = action.payload.engine;
      state.error = undefined;
    },
    [setCoveoEngine.rejected.toString()]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const selectCoveoEngine = (state: TCoveoState) => {
  return state.coveo.engine;
};
export const { pushValue } = coveoSlice.actions;

export default coveoSlice.reducer;
