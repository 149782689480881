import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import Handle from '../errors/ErrorHandler';

const initialState = {
  session: null,
  status: 'idle',
  error: null,
};

export const fetchSessionInitial = createAsyncThunk<
  any>('session/fetchSession', async () => {
  return new Promise((resolve, reject) => {
    fetch(window.location.origin + "/api/auth/session", {
      method: 'GET',
      credentials: 'include',
      headers: {
        'accept': 'application/json'
      },
    })
      .then(response => response.json())
      .then((res: any) => {
        if (Handle.WasThereAnError(res)) {
          reject(res);
        } else {
          resolve({ session: res });
        }
      })
      .catch(err => {
        reject(err);
      });
  });
});

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
      pushValue: (state, action) => { 
          state.session = action.payload;
      }
  },
  extraReducers: {
    [fetchSessionInitial.pending.toString()]: (state, action) => {
      state.status = 'loading';
    },
    [fetchSessionInitial.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded';
      // Add any fetched posts to the array
      state.session = action.payload.session; //state.files.concat(action.payload);
    },
    [fetchSessionInitial.rejected.toString()]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const selectSession = (state: any) => state.session.session;
export const { pushValue } = sessionSlice.actions;

export default sessionSlice.reducer;
