import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import sessionReducer from './sessionSlice';
import caseReducer  from './caseSlice';
import casesReducer  from './casesSlice'; 
import projectsReducer  from './ProjectsSlice';
import coveoReducer from './coveoSlice';

export default configureStore({
  reducer: {
    projects: projectsReducer,
    session: sessionReducer,
    case: caseReducer, 
    cases: casesReducer,
    coveo: coveoReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // This allows coveo engine to exist in reducers
      serializableCheck: {
        ignoredActionPaths: ['payload.engine'],
        ignoredPaths: ['coveo.engine'],
      }
    }),
});