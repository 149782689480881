import Handle from "../components/errors/ErrorHandler";
import doNotTrack from "./donottrack";

export const supportFetch = {
    get,
    post,
    put,
    _delete
};

function get(url: string) {
    const requestOptions: RequestInit = {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'DoNotTrack': doNotTrack().toString()
        },
    };
    if(!url.startsWith('http')) {
        url = process.env.SUPPORT_PORTAL_API + url;
    }
    return fetch(url, requestOptions).then(handleResponse).catch(catchResponse);
}

function post(url: string, body: any) {
    const requestOptions: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'DoNotTrack': doNotTrack().toString()
        },
        body: JSON.stringify(body)
    };
    if(!url.startsWith('http')) {
        url = process.env.SUPPORT_PORTAL_API + url;
    }
    return fetch(url, requestOptions).then(handleResponse).catch(catchResponse);
}

function put(url: string, body: any) {
    const requestOptions: RequestInit = {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(body)
    };
    if(!url.startsWith('http')) {
        url = process.env.SUPPORT_PORTAL_API + url;
    }
    return fetch(url, requestOptions).then(handleResponse).catch(catchResponse);   
}

//prefixed with underscored because delete is a reserved word in javascript
function _delete(url: string) {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
    };
    if(!url.startsWith('http')) {
        url = process.env.SUPPORT_PORTAL_API + url;
    }
    return fetch(url, requestOptions).then(handleResponse).catch(catchResponse);   
}

//helper functions

function handleResponse(response: Response) {
    return response.json().then((res: any) => {
        console.debug(res);
        if (Handle.WasThereAnError(res)) {
            Handle.ErrorHandler(res); 
            return Promise.reject(res);
        }

        return res;
    });
}
function catchResponse(error: any) {
    Handle.ErrorHandler(error); 
    return Promise.reject(error);
}