import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supportFetch } from '../../middleware/supportFetch';
import Handle from '../errors/ErrorHandler';


const initialState = {
    projects: null,
    status: 'idle',
    error: null,
};


export const fetchProjectsInitial = createAsyncThunk<
    any>('projects/fetchProjects', async (query: any) => {
    return new Promise((resolve, reject) => {
        supportFetch.post(`/public/v1.0/projects`,{
            ...query,
          }).then((res: any) => {
                if (Handle.WasThereAnError(res)) {
                    reject(res);
                } else {
                    resolve({ projects: res.projects, paging: res.paging  });
                }
            })
            .catch(err => {
                reject(err);
            });
    });
});


const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        pushValue: (state, action) => {
            state.projects = action.payload;
        }
    },
    extraReducers: {
        [fetchProjectsInitial.pending.toString()]: (state, action) => {
            state.status = 'loading';
        },
        [fetchProjectsInitial.fulfilled.toString()]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched posts to the array
            state.projects = action.payload.projects; //state.files.concat(action.payload);
        },
        [fetchProjectsInitial.rejected.toString()]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});


export const selectProjects = (state: any) => state.projects.projects;
export const { pushValue } = projectsSlice.actions;


export default projectsSlice.reducer;