import Router from 'next/router';

const ErrorHandler = (json_result: any, throwing: boolean = false) => {
    if(json_result.message) {
        ErrorMessageHandler(json_result.message, throwing);
    } else if(throwing) {
        throw new Error('UNHANDLED IN ERRORHANDLER.TS')
    }
}
const ErrorMessageHandler = (message: any, throwing: boolean = false) => {
    console.error(message);
    if(message == 'INVALID_SESSION_ID' || message == 'UnauthorizedError') {
        if(window.location.pathname != null && window.location.pathname != '/') {

            Router.push(`/api/auth/login?url=${window.location.href}`);
            //Router.reload();
        }
        else {
            Router.push(`/api/auth/login`);
            //Router.reload();
        }
    } else if(throwing) {
        throw new Error('UNHANDLED IN ERRORHANDLER.TS')
    }
}

const WasThereAnError = (json_result: any) => {
    if(json_result.success == false) {
        return true;
    } else {
        return false;
    }
}

const Handle =  { WasThereAnError, ErrorHandler, ErrorMessageHandler }
export default Handle;