import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supportFetch } from '../../middleware/supportFetch';
import Handle from '../errors/ErrorHandler';   

const initialState = {
  cases: [],
  status: 'idle',
  error: null, 
};

export const fetchCasesByQuery = createAsyncThunk<
  any,
  {
    paging: any;
    predicate: any | null;
  }
>('cases/fetchCases', async (query: any) => {  
    if (!query.paging.rows) {
      query.paging.rows = 10;
    }
  
    if (!query.paging.page) {
      query.paging.page = 0;
    }   

  return new Promise((resolve, reject) => {
    supportFetch.post(`/public/v1.0/cases`, {
        // ...query,
        paging : query.paging,
        filter : query.predicate,
       })     .then((res: any) => {
        if (Handle.WasThereAnError(res)) { 
          reject(res);
        } else {

          // retain/pass values back
          if(query.predicate) {
            res.predicate = query.predicate;
          }

          if(query.paging.sortBy) {
            res.paginate.sortBy = query.paging.sortBy;
          }

          if(query.paging.status) {
            res.paginate.status = query.paging.status;
          }

          if(query.paging.type) {
            res.paginate.type = query.paging.type;
          } 
              resolve(res); 
        }
      })
      .catch(err => { 
        reject(err);
      });
    // fetch(process.env.SUPPORT_PORTAL_API + `/public/v1.0/cases`, {
    //   method: 'POST',
    //   credentials: 'include',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Accept': 'application/json'
    //   },
    //   body: JSON.stringify({
    //    // ...query,
    //    paging : query.paging,
    //    filter : query.predicate,
    //   }),
    // })
    //   .then(response => response.json())
    //   .then((res: any) => {
    //     if (Handle.WasThereAnError(res)) {
    //       reject(res);
    //     } else {

    //       // retain/pass values back
    //       if(query.predicate) {
    //         res.predicate = query.predicate;
    //       }

    //       if(query.paging.sortBy) {
    //         res.paginate.sortBy = query.paging.sortBy;
    //       }

    //       if(query.paging.status) {
    //         res.paginate.status = query.paging.status;
    //       }

    //       if(query.paging.type) {
    //         res.paginate.type = query.paging.type;
    //       }
    //       resolve(res);
    //     }
    //   })
    //   .catch(err => {
    //     reject(err);
    //   });
  });
});

const casesSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCasesByQuery.pending.toString()]: (state, action) => {
      state.status = 'loading'; 
    },
    [fetchCasesByQuery.fulfilled.toString()]: (state, action) => {
      state.status = 'succeeded';   
      state.cases = action.payload;  
    },
    [fetchCasesByQuery.rejected.toString()]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const selectCases = (state: any) => { 
  
  return state.cases.cases;
};

export default casesSlice.reducer;
